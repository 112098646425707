import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Toast } from '@mweb/zappos-ui/Toast';

import type { AppState } from 'types/app';
import useMartyContext from 'hooks/useMartyContext';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import { cn } from 'helpers/classnames';

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & { className?: string };

export const CartErrors = ({ cart, className }: Props) => {
  const {
    testId,
    marketplace: {
      cart: { cartName }
    }
  } = useMartyContext();

  const {
    error,
    cartObj: { savedItems }
  } = cart;

  if (!error && !savedItems?.length) {
    return null;
  }

  const oosMsg = savedItems?.length ? `An item in your ${cartName} is out of stock. It will not show when you proceed to checkout.` : '';

  return (
    <div className={cn('my-3', className)}>
      <div className="flex flex-col gap-y-3">
        <Toast data-test-id={testId('oosError')} msg={oosMsg ?? ''} isDismissible={false} isShown={!!oosMsg} variant="error" />
        <Toast data-test-id={testId('cartError')} msg={error ?? ''} isDismissible={false} isShown={!!error} variant="error" />
      </div>
    </div>
  );
};

export const mapStateToProps = (state: AppState) => {
  const { cart } = state;

  return {
    cart
  };
};

const connector = connect(mapStateToProps);
const ConnectedCartErrors = connector(CartErrors);
export default withErrorBoundary('CartErrors', ConnectedCartErrors);
